import React from 'react';
import '../Animation.less';

import { ReactComponent as ES } from '../../SVG/tiles/ES.svg';
import { ReactComponent as SW } from '../../SVG/tiles/SW.svg';
import { ReactComponent as NW } from '../../SVG/tiles/NW.svg';
import { ReactComponent as NE } from '../../SVG/tiles/NE.svg';

import { ReactComponent as NS } from '../../SVG/tiles/NS.svg';
import { ReactComponent as EW } from '../../SVG/tiles/EW.svg';

import { ReactComponent as NES } from '../../SVG/tiles/NES.svg';
import { ReactComponent as ESW } from '../../SVG/tiles/ESW.svg';
import { ReactComponent as NSW } from '../../SVG/tiles/NSW.svg';
import { ReactComponent as NEW } from '../../SVG/tiles/NEW.svg';

import { ReactComponent as NESW } from '../../SVG/tiles/NESW.svg';
import { ReactComponent as Empty } from '../../SVG/tiles/emptyTile.svg';
import { ReactComponent as None } from '../../SVG/tiles/noneTile.svg';

import { ReactComponent as Astronaute } from '../../SVG/astronaute.svg';
import { ReactComponent as O2 } from '../../SVG/O2.svg';
import { ReactComponent as Spawn } from '../../SVG/spawn.svg';
import { ReactComponent as Hole } from '../../SVG/blackHole.svg';
import { ReactComponent as Hole2 } from '../../SVG/blackHole2.svg';
import { ReactComponent as Medikit } from '../../SVG/medikitBis.svg';
import { ReactComponent as Bandage } from '../../SVG/bandage.svg';
import { ReactComponent as Bandage2 } from '../../SVG/bandage2.svg';
import { ReactComponent as Gauge } from '../../SVG/gauge.svg';
import { ReactComponent as Rotate } from '../../SVG/rotate.svg';

const TILES = {
  ES: ES,
  SW: SW,
  NW: NW,
  NE: NE,

  NS: NS,
  EW: EW,

  NESW: NESW,

  NES: NES,
  ESW: ESW,
  NSW: NSW,
  NEW: NEW
};

const Tile = ({
  type,
  className = '',
  style,
  onClick,
  showSpawn,
  showEmpty,
  isAstronaute,
  isO2,
  isSpawn,
  hole,
  isMedikit,
  bandage,
  isGauge,
  isRotate
}) => {
  let SvgTile = TILES[type];
  if (!SvgTile) {
    SvgTile = showEmpty ? Empty : None;
  }
  return (
    <svg className={`tile ${className}`} style={style} onClick={onClick}>
      <SvgTile style={style} />
      {isO2 && <O2 className="popAnimation" />}
      {isSpawn && showSpawn && <Spawn />}
      {isMedikit && <Medikit />}
      {hole && hole === '1' && <Hole className="rotationHole" />}
      {hole && hole === '2' && <Hole2 className="rotationHole" />}
      {bandage && bandage === 'band_1' && <Bandage />}
      {bandage && bandage === 'band_2' && <Bandage2 />}
      {isGauge && <Gauge />}
      {isRotate && <Rotate className="spinRotate" />}
      {isAstronaute && <Astronaute />}
    </svg>
  );
};

export default Tile;
