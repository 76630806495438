import React, { useState, useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import Switch from '@material-ui/core/Switch';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { ReactComponent as O2 } from '../../SVG/O2.svg';
import { ReactComponent as Hole } from '../../SVG/blackHole.svg';
import { ReactComponent as Timer } from '../../SVG/chronometer.svg';
import { ReactComponent as Rotate } from '../../SVG/rotate.svg';
import './Lobby.less';
import { useTranslation } from 'react-i18next';

const IZI = {
  duree: 1,
  oxygenRefill: 80,
  holeReduce: 0.5
};
const NORMAL = {
  duree: 2,
  oxygenRefill: 60,
  holeReduce: 1
};
const HARD = {
  duree: 4,
  oxygenRefill: 30,
  holeReduce: 1.5
};
const ALL_DIFFICULTIES = [IZI, NORMAL, HARD];

const DifficultyConfig = ({
  oxygenRefill,
  setOxygenRefill,
  holeReduce,
  setHoleReduce,
  duree,
  setDuree,
  hasRotate,
  setHasRotate,
  nbRotate,
  setNbRotate
}) => {
  const { t } = useTranslation();
  const [difficulty, setDifficulty] = useState(3);

  useEffect(() => {
    const newConf = ALL_DIFFICULTIES[difficulty];
    if (newConf) {
      setOxygenRefill(newConf.oxygenRefill);
      setHoleReduce(newConf.holeReduce);
      setDuree(newConf.duree);
    }
  }, [difficulty]);

  useEffect(() => {
    const found = ALL_DIFFICULTIES.findIndex(
      d =>
        d.oxygenRefill === oxygenRefill &&
        d.duree === duree &&
        d.holeReduce === holeReduce
    );
    if (found !== -1) {
      setDifficulty(found);
    } else {
      setDifficulty(3); // custom
    }
  }, [holeReduce, oxygenRefill, duree]);

  const handleDureeChange = e => {
    const nextDuree = parseFloat(e.target.value);
    if (nextDuree && !isNaN(nextDuree)) {
      if (nextDuree <= 0) {
        setDuree(1);
      } else if (nextDuree > 10) {
        setDuree(10);
      } else {
        setDuree(nextDuree);
      }
    }
  };

  const handleOxygenRefillChange = e => {
    const nextRefill = parseFloat(e.target.value);
    if (nextRefill && !isNaN(nextRefill)) {
      if (nextRefill >= 100) {
        setOxygenRefill(100);
      } else {
        setOxygenRefill(nextRefill);
      }
    }
  };
  const checkMinimumRefill = () => {
    if (oxygenRefill <= 10) {
      setOxygenRefill(10);
    }
  };

  const handleHoleReduceChange = e => {
    const nextReduce = parseFloat(e.target.value);
    if (nextReduce && !isNaN(nextReduce)) {
      if (nextReduce >= 2) {
        setHoleReduce(2);
      } else {
        setHoleReduce(nextReduce);
      }
    }
  };

  const handleNbRotateChange = e => {
    const nextNbRotate = parseFloat(e.target.value);
    if (nextNbRotate && !isNaN(nextNbRotate)) {
      setNbRotate(nextNbRotate);
    }
  };

  const checkMinimumReduce = () => {
    if (holeReduce < 0) {
      setHoleReduce(0);
    }
  };

  const selectAllText = e => {
    e.target.select();
  };

  const toggleHasRotate = () => {
    setHasRotate(!hasRotate);
  };

  return (
    <>
      <RadioGroup row aria-label="position" value={difficulty}>
        <FormControlLabel
          value={0}
          control={<Radio color="primary" />}
          classes={{ label: 'radioLabel' }}
          label="Easy"
          labelPlacement="top"
          onClick={() => setDifficulty(0)}
        />
        <FormControlLabel
          value={1}
          control={<Radio color="primary" />}
          classes={{ label: 'radioLabel' }}
          label="Normal"
          labelPlacement="top"
          onClick={() => setDifficulty(1)}
        />
        <FormControlLabel
          value={2}
          control={<Radio color="primary" />}
          classes={{ label: 'radioLabel' }}
          label="Hard"
          labelPlacement="top"
          onClick={() => setDifficulty(2)}
        />
        <FormControlLabel
          value={3}
          control={<Radio color="secondary" />}
          classes={{ label: 'radioLabel' }}
          label="Custom"
          labelPlacement="top"
          onClick={() => setDifficulty(3)}
        />
      </RadioGroup>
      <div className="renderDifficulty">
        <Timer className="iconConfig" />
        <TextField
          type="number"
          title={t('difficulty.missionDuration')}
          className="inputConfig"
          value={duree}
          onChange={handleDureeChange}
          inputProps={{ min: 1, max: 6 }}
          onFocus={selectAllText}
        />
        <O2 viewBox="0 0 512 512" className="iconConfig" />
        <TextField
          type="number"
          title={t('difficulty.oxygenWon')}
          className="inputConfig"
          value={oxygenRefill}
          onBlur={checkMinimumRefill}
          onChange={handleOxygenRefillChange}
          inputProps={{ min: 10 }}
          onFocus={selectAllText}
        />
        <Hole viewBox="0 0 512 512" className="iconConfig" />
        <TextField
          type="number"
          title={t('difficulty.oxygenLost')}
          className="inputConfig"
          value={holeReduce}
          inputProps={{ step: 0.5, min: 0 }}
          onBlur={checkMinimumReduce}
          onChange={handleHoleReduceChange}
          onFocus={selectAllText}
        />
      </div>
      <div className="cursor">
        <Switch checked={hasRotate} onClick={toggleHasRotate} color="primary" />
        <label onClick={toggleHasRotate}>
          {t('difficulty.powerShufflers')}
        </label>
        <Rotate
          onClick={toggleHasRotate}
          className={`powerRotate ${hasRotate ? 'spinRotate' : 'desactivate'}`}
          viewBox="-100 -100 701 701"
        />
        {hasRotate && (
          <>
            <div className="multiplier">X</div>
            <TextField
              type="number"
              title={t('difficulty.nbShufflers')}
              className="inputConfig"
              value={nbRotate}
              inputProps={{ min: 1, max: 15 }}
              onChange={handleNbRotateChange}
              onFocus={selectAllText}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DifficultyConfig;
