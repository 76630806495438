import React from 'react';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import './End.less';

const End = ({ socket, room, me }) => {
  const { t } = useTranslation();
  const playAgain = () => {
    socket.emit('playAgain');
  };

  const renderVictory = () => (
    <>
      <div className="title">{t('end.won')}</div>
      {me.isHost && <div className="text">{t('end.wonTxt')}</div>}
    </>
  );

  const renderDefeat = () => (
    <>
      <div className="title">{t('end.lost')}</div>
      {me.isHost && (
        <div className="text">
          <div>{t('end.lostTxt')}</div>
          <div>{t('end.lostTxtBrief')}</div>
        </div>
      )}
    </>
  );

  return (
    <div className={`end ${room.hasWon ? 'win' : 'lost'}`}>
      {room.hasWon ? renderVictory() : renderDefeat()}
      {me.isHost && (
        <Button
          onClick={playAgain}
          className="playAgain"
          color="primary"
          variant="contained"
        >
          {t('end.restart')}
        </Button>
      )}
    </div>
  );
};

export default End;
