import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { LARGEUR, HAUTEUR, ALL_TILES_TYPE } from '../../Constantes';
import { useWindowSize } from '../CustomHooks';
import './MapEditor.less';
import Tile from '../Tiles/Tile';
import { ReactComponent as Back } from '../../SVG/back.svg';

const emptyLignes = [...Array(HAUTEUR).keys()];
const emptyTiles = [...Array(LARGEUR).keys()];
const emptyMap = [];

emptyLignes.forEach(l => {
  const ligne = [];
  emptyTiles.forEach(() => {
    ligne.push({ type: '' });
  });
  emptyMap.push(ligne);
});

const MapEditor = () => {
  const { t } = useTranslation();
  const [tileSize, setTileSize] = useState(50);
  const [width, height] = useWindowSize();
  const [map, setMap] = useState(emptyMap);
  const [pinceau, setPinceau] = useState(ALL_TILES_TYPE[0]);

  // Save Modal
  const [isSaveOpen, setIsSaveOpen] = useState(false);
  const [mapName, setMapName] = useState('');
  const [alreadyExist, setAlreadyExist] = useState(false);
  const mapNameRef = useRef(null);

  // Load Modal
  const [isLoadOpen, setIsLoadOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [mapSelected, setMapSelected] = useState(null);

  const history = useHistory();

  useEffect(() => {
    const size = Math.min(width / LARGEUR, height / (HAUTEUR + 3)); // Map de 10 * 6 tiles
    setTileSize(size - (15 * size) / 100);
  }, [height, width]);

  const selectTile = (x, y) => {
    let nextMap = [...map];
    if (pinceau === 'spawn') {
      // Erase last Spawn :
      nextMap.forEach(ligne => ligne.forEach(t => delete t.isSpawn));
      nextMap[y][x] = { ...nextMap[y][x], isSpawn: true };
    } else {
      nextMap[y][x] = { type: pinceau };
    }
    setMap(nextMap);
  };

  // donne direct le focus au champs input
  useEffect(() => {
    if (isSaveOpen) {
      mapNameRef.current.focus();
    }
  }, [isSaveOpen]);

  const handleSubmitByEnter = e => {
    e.preventDefault();
    saveMap();
  };

  const closeSave = () => {
    setIsSaveOpen(false);
    setAlreadyExist(false);
  };

  const saveMap = () => {
    const localStore = localStorage.getItem('maps');
    const localMaps = localStore ? JSON.parse(localStore) : [];
    const foundIndex = localMaps.findIndex(m => m.name === mapName);
    if (foundIndex === -1) {
      localMaps.push({ name: mapName, tiles: map });
      localStorage.setItem('maps', JSON.stringify(localMaps));
      closeSave();
    } else if (foundIndex !== -1 && alreadyExist) {
      localMaps[foundIndex] = { name: mapName, tiles: map };
      localStorage.setItem('maps', JSON.stringify(localMaps));
      closeSave();
    } else {
      setAlreadyExist(true);
    }
  };

  const loadMap = () => {
    setMap(mapSelected.tiles);
    setIsLoadOpen(false);
    setIsConfirmOpen(false);
    setMapName(mapSelected.name);
    setMapSelected(null);
  };

  const deleteMap = () => {
    const localStore = localStorage.getItem('maps');
    const localMaps = localStore ? JSON.parse(localStore) : [];
    const foundIndex = localMaps.findIndex(m => m.name === mapSelected.name);
    if (foundIndex !== -1) {
      localMaps.splice(foundIndex, 1);
      localStorage.setItem('maps', JSON.stringify(localMaps));
    }
    setIsLoadOpen(false);
    setIsConfirmOpen(false);
    setMapSelected(null);
  };

  const renderSaveDialog = () => {
    return (
      <Dialog
        open={isSaveOpen}
        TransitionComponent={Slide}
        keepMounted
        onClose={closeSave}
      >
        <DialogTitle>{t('mapEditor.shipName')}</DialogTitle>
        <DialogContent>
          <form
            className="saveMapForm"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmitByEnter}
          >
            <TextField
              autoFocus
              variant="outlined"
              value={mapName}
              onChange={e => setMapName(e.target.value)}
              error={alreadyExist}
              inputRef={mapNameRef}
            />
            {alreadyExist && (
              <div className="warningMessage">
                {t('mapEditor.alreadyExist')}
              </div>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={saveMap}
            color="primary"
            variant="contained"
            disabled={mapName.length === 0}
          >
            {alreadyExist ? t('mapEditor.yesOverride') : t('mapEditor.save')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderMapSelection = () => {
    const localStore = localStorage.getItem('maps');
    const localMaps = localStore ? JSON.parse(localStore) : [];
    if (localMaps.length === 0) {
      return <div>{t('mapEditor.noShip')}</div>;
    } else {
      return (
        <div className="allMapsChoices">
          {renderConfirm()}
          {localMaps.map((m, mapIndex) => (
            <div className="mapWrapper" key={mapIndex}>
              <>
                <div
                  className={`tiles ${
                    m.name === (mapSelected && mapSelected.name) ? 'active' : ''
                  }`}
                >
                  {m.tiles.map((ligne, y) => (
                    <div key={y}>
                      {ligne.map((t, x) => (
                        <Tile
                          key={x}
                          type={t.type}
                          style={{
                            height: '2vw',
                            width: '2vw' //tileSize / 5 + 'px'
                          }}
                          onClick={() => setMapSelected(m)}
                          showEmpty
                          showSpawn
                          isSpawn={t.isSpawn}
                        />
                      ))}
                      <br />
                    </div>
                  ))}
                </div>
                <div className="mapName">{m.name}</div>
              </>
            </div>
          ))}
        </div>
      );
    }
  };

  const renderConfirm = () => {
    return (
      <Dialog
        open={!!isConfirmOpen}
        TransitionComponent={Slide}
        keepMounted
        onClose={() => setIsConfirmOpen(false)}
      >
        <DialogTitle>
          {t('mapEditor.alertDestroyShip0')}
          {isConfirmOpen !== 'delete' ? t('mapEditor.alertDestroyShip1') : ''}
          {t('mapEditor.alertDestroyShip2')}
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={isConfirmOpen !== 'delete' ? loadMap : deleteMap}
            color="secondary"
            variant="contained"
            className={isConfirmOpen === 'delete' ? 'deleteButton' : ''}
          >
            {isConfirmOpen !== 'delete'
              ? t('mapEditor.loadAnyway')
              : t('mapEditor.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderLoadDialog = () => {
    return (
      <Dialog
        open={isLoadOpen}
        TransitionComponent={Slide}
        keepMounted
        onClose={() => setIsLoadOpen(false)}
        maxWidth="lg"
      >
        <DialogTitle>{t('mapEditor.chooseShip')}</DialogTitle>
        <DialogContent>{renderMapSelection()}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsConfirmOpen('delete')}
            color="secondary"
            variant="contained"
            disabled={mapSelected === null}
            className="deleteButton"
          >
            {t('mapEditor.delete')}
          </Button>
          <Button
            onClick={() => setIsConfirmOpen('load')}
            color="secondary"
            variant="contained"
            disabled={mapSelected === null}
          >
            {t('mapEditor.load')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const verifSpawn = () => {
    const foundSpawnLigne = map.filter(
      ligne => ligne.filter(tile => tile.isSpawn).length > 0
    );
    const foundSpawnTile =
      foundSpawnLigne.length > 0
        ? foundSpawnLigne[0].filter(t => t.isSpawn)
        : [];
    const tileSpawn = foundSpawnTile.length > 0 ? foundSpawnTile[0] : false;
    if (tileSpawn) {
      if (tileSpawn.type !== '') {
        return true;
      } else {
        toast.error(t('mapEditor.invalidShip.badSpawn'), {
          toastId: 'badSpawn'
        });
        return false;
      }
    } else {
      toast.error(t('mapEditor.invalidShip.noSpawn'), { toastId: 'noSpawn' });
      return false;
    }
  };

  const verifNbTiles = () => {
    const validTiles = map.flatMap(ligne => ligne).filter(t => t.type !== '');
    if (validTiles.length > 11) {
      return true;
    }
    toast.error(t('mapEditor.invalidShip.tooSmall'), { toastId: 'tooSmall' });
    return false;
  };

  const verifVaisseau = () => {
    const hasSpawn = verifSpawn();
    const hasAtLeast10Tiles = verifNbTiles();
    if (hasSpawn && hasAtLeast10Tiles) {
      setIsSaveOpen(true);
    }
  };

  return (
    <>
      <div className="GoBack">
        <Button
          onClick={() => history.push('/home')}
          variant="contained"
          color="primary"
        >
          <Back />
        </Button>
      </div>
      <div className="mapEditor">
        <div className="textCreate">{t('mapEditor.explain')}</div>
        <div className="map">
          {map.map((ligne, y) => (
            <div key={y}>
              {ligne.map((t, x) => (
                <Tile
                  key={x}
                  type={t.type}
                  style={{ height: tileSize + 'px', width: tileSize + 'px' }}
                  onClick={() => selectTile(x, y, t.isSpawn)}
                  showEmpty
                  showSpawn
                  isSpawn={t.isSpawn}
                />
              ))}
              <br />
            </div>
          ))}
        </div>
        <div className="toolbox">
          {ALL_TILES_TYPE.map((t, index) => (
            <Tile
              key={index}
              type={t}
              style={{ height: tileSize + 'px', width: tileSize + 'px' }}
              onClick={() => setPinceau(t)}
              className={t === pinceau ? 'active' : 'notActive'}
              showEmpty
              showSpawn
              isSpawn={t === 'spawn'}
            />
          ))}
        </div>
        <div className="saveBox">
          <Button
            onClick={() => setIsLoadOpen(true)}
            color="secondary"
            variant="contained"
          >
            {t('mapEditor.shipList')}
          </Button>
          <Button onClick={verifVaisseau} color="primary" variant="contained">
            {t('mapEditor.save')}
          </Button>
        </div>
        {renderSaveDialog()}
        {renderLoadDialog()}
      </div>
    </>
  );
};

export default MapEditor;
