import React, { useState, useEffect } from 'react';
import './Ping.less';

let timerID;

const Ping = ({ socket }) => {
  const MAX_PING_TAB_SIZE = 4;
  const [ping, setPing] = useState(1);
  const [pingTab, setPingTab] = useState([]);
  const [pingColor, setPingColor] = useState('pingGreen');

  useEffect(() => {
    doPing();
    timerID = setInterval(doPing, 1000);
    return () => {
      clearInterval(timerID);
    };
  }, []);

  useEffect(() => {
    if (pingTab.length >= MAX_PING_TAB_SIZE) {
      setPingTab(pingTab.slice(1, MAX_PING_TAB_SIZE));
    }
    const avg = pingTab.reduce((a, b) => a + b, 0) / pingTab.length;
    setPing(Math.ceil(avg));
  }, [pingTab]);

  useEffect(() => {
    let color = '';
    switch (true) {
      case ping < 50:
        color = 'pingGreen';
        break;
      case ping > 50 && ping <= 300:
        color = 'pingOrange';
        break;
      case ping > 300:
        color = 'pingRed';
        break;
      default:
        color = 'pingGreen';
    }
    setPingColor(color);
  }, [ping]);

  const doPing = () => {
    var start = Date.now();
    socket.emit('myPing', () => {
      const delta = Date.now() - start;
      setPingTab(tab => [...tab, delta / 2]);
    });
  };

  return <div className={`ping ${pingColor}`}>{ping}ms</div>;
};

export default Ping;
