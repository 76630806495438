import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import SwipeableViews from 'react-swipeable-views';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { randLetter } from '../utils';
import Ping from './Ping/Ping';
import { isProduction, VERSION } from '../Constantes';
import { ReactComponent as Spaceship } from '../SVG/spaceship.svg';
import { ReactComponent as Tools } from '../SVG/tools.svg';

import './RoomInit.less';

const RoomInit = ({ socket }) => {
  const { t } = useTranslation();
  const initName = localStorage.getItem('pseudo') || '';
  const [name, setName] = useState(
    isProduction
      ? initName
      : initName.substring(0, 3) + randLetter() + randLetter()
  );
  const [trigramme, setTrigramme] = useState('');
  const [errorJoin, setErrorJoin] = useState(false);
  const [createClicked, setCreateClicked] = useState(false);
  const [alreadyBeenClicked, setAlreadyBeenClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const trigrammeInput = useRef(null);
  const pseudoInput = useRef(null);

  const history = useHistory();

  //Ping
  const [isPingActivated, setIsPingActivated] = useState(false);

  //Easter Egg:
  const [isEaster, setIsEaster] = useState(false);
  const [eggTimeOut, setEggTimeOut] = useState(null);
  const [eggs, setEggs] = useState(0);
  const easterClick = () => {
    setEggs(eggs + 1);
  };
  useEffect(() => {
    if (eggs >= 1) {
      if (eggTimeOut) {
        clearTimeout(eggTimeOut);
      }
      setEggTimeOut(
        setTimeout(() => {
          setEggs(0);
          console.log('clear');
        }, 800)
      );
    }
    if (eggs >= 4) {
      setIsEaster(true);
    }
  }, [eggs]);
  // </EasterEgg>___________________

  //Gestion des tabs :
  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeName = e => {
    setName(e.target.value);
  };

  const handleChangeTrigramme = e => {
    if (e.target.value.length <= 3) {
      setTrigramme(e.target.value.trim().toUpperCase());
    }
  };

  const handleCreateRoom = () => {
    if (!createClicked) {
      if (socket.connected) {
        setCreateClicked(true);
        socket.emit('room.create', retour => {
          setCreateClicked(false);
          if (!retour.isOk) {
            toast.error('Erreur : (never trigger) probleme serveur (?)');
          }
        });
      } else {
        toast.error(t('roomInit.error.noSocket'), { toastId: 'noSocket' });
      }
    }
  };

  const handleSubmitByEnter = e => {
    e.preventDefault();
    handleJoinRoom();
  };

  const handleJoinRoom = () => {
    setAlreadyBeenClicked(true);
    if (trigramme.length === 3 && name.length > 0) {
      localStorage.setItem('pseudo', name);
      setIsLoading(true);
      setErrorJoin(false);
      socket.emit('room.join', trigramme, name, retour => {
        setIsLoading(false);
        if (!retour.isOk) {
          toast.error(t(retour.errorCode, retour.errorParam), {
            toastId: 'errorFromServer'
          });
          setErrorJoin(true);
          trigrammeInput.current.focus();
          trigrammeInput.current.select();
        } else {
          trigrammeInput.current.blur();
          pseudoInput.current.blur();
        }
      });
    } else if (name.length === 0) {
      toast.error(t('roomInit.error.emptyLogin'), { toastId: 'emptyLogin' });
    } else {
      toast.error(t('roomInit.error.roomIdWrong'), { toastId: 'roomIdWrong' });
    }
  };

  return (
    <div className="roomInit">
      {isPingActivated && <Ping socket={socket} />}
      <div
        className="version"
        onClick={() => setIsPingActivated(!isPingActivated)}
      >
        {VERSION === 'beta' ? 'Version β' : 'v' + VERSION}
      </div>
      <div className="linkWrapper">
        <a className="linkMailTo" href="mailto:webmaster.idontcare@gmail.com">
          {t('roomInit.linkBug')}
        </a>
      </div>
      <div className="logoWrapper">
        <Spaceship
          className={`logo ${isEaster && 'easterEgged'}`}
          onClick={easterClick}
        />
      </div>
      <div className={`title ${isEaster && 'easterEgged'}`}>SpaceCheap</div>
      <div>
        <Paper>
          <Tabs
            value={currentTab}
            onChange={(e, newValue) => setCurrentTab(newValue)}
            indicatorColor={`${currentTab === 0 ? 'primary' : 'secondary'}`}
            textColor={`${currentTab === 0 ? 'primary' : 'secondary'}`}
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label={t('roomInit.join')} />
            <Tab label={t('roomInit.create')} />
          </Tabs>
          <SwipeableViews
            resistance
            index={currentTab}
            onChangeIndex={newTab => setCurrentTab(newTab)}
          >
            <form
              className="tab"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmitByEnter}
            >
              <TextField
                label={t('roomInit.login')}
                variant="outlined"
                color="primary"
                value={name}
                inputRef={pseudoInput}
                onChange={handleChangeName}
                error={name.length === 0 && alreadyBeenClicked}
              />
              <TextField
                label={t('roomInit.code')}
                variant="outlined"
                color="primary"
                value={trigramme}
                onChange={handleChangeTrigramme}
                inputRef={trigrammeInput}
                inputProps={{ maxLength: 3 }}
                error={errorJoin}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={trigramme.length < 1}
                type="submit"
              >
                {isLoading ? (
                  <CircularProgress size={24} className="loaderJoinRoom" />
                ) : (
                  <div>{t('roomInit.goBtn')}</div>
                )}
              </Button>
            </form>
            <div className="tab">
              <div className="warningMessage">
                {t('roomInit.warningExplain')}
              </div>
              <div className="buttonWrapper">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCreateRoom}
                  disabled={createClicked}
                >
                  {t('roomInit.startMission')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push('/mapEditor')}
                >
                  {t('roomInit.garage')} <Tools className="margin-left" />
                </Button>
              </div>
            </div>
          </SwipeableViews>
        </Paper>
      </div>
    </div>
  );
};

export default RoomInit;
