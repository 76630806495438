import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';

import { isProduction } from '../../Constantes';
import { ReactComponent as Arrow } from '../../SVG/arrowLEFT.svg';
import { ReactComponent as Medikit } from '../../SVG/medikitBis.svg';
import { ReactComponent as Bandage } from '../../SVG/bandage.svg';
import { ReactComponent as Bandage2 } from '../../SVG/bandage2.svg';
import { ReactComponent as O2 } from '../../SVG/O2.svg';
import { ReactComponent as O2Empty } from '../../SVG/O2Empty.svg';

import './Actions.less';
import { useTranslation } from 'react-i18next';

const Actions = ({
  socket,
  room,
  me,
  isOnMedikit,
  isOnGauge,
  isOnO2,
  playSound
}) => {
  const { t } = useTranslation();
  const ALL_ARROWS = ['N', 'E', 'S', 'W'];
  const [isDisabled, setIsDisabled] = useState(false);
  const [isBandageOpen, setIsBandageOpen] = useState(false);

  // Cheat for dev :
  const onKeyPressed = ({ key }) => {
    if (!isProduction) {
      switch (key) {
        case 'ArrowUp':
          sendMove('N');
          break;
        case 'ArrowDown':
          sendMove('S');
          break;
        case 'ArrowLeft':
          sendMove('W');
          break;
        case 'ArrowRight':
          sendMove('E');
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onKeyPressed);

    // Remove event listeners
    return () => {
      window.removeEventListener('keyup', onKeyPressed);
    };
  }, []);

  const sendMove = power => {
    if (!isDisabled) {
      setIsDisabled(true);
      socket.emit('astronaute.sendMove', power, () => {
        setIsDisabled(false);
      });
    }
  };

  const tryOpenBandage = () => {
    if (room.backpack.length > 1) {
      toast.error(t('actions.inventFull'), {
        toastId: 'inventFull'
      });
      playSound('backpackError');
    } else {
      setIsBandageOpen(true);
    }
  };

  const selectBandage = type => {
    if (room.backpack.length > 1) {
      toast.error(t('actions.inventFull'), {
        toastId: 'inventFull'
      });
      playSound('backpackError');
    } else if (!isOnMedikit) {
      toast.error(t('actions.wrongTile'), {
        toastId: 'wrongTile'
      });
      playSound('backpackError');
      setIsBandageOpen(false);
    } else {
      socket.emit('backpack.pickUp.bandage', type);
      playSound('inventoryPut');
      if (room.backpack.length === 1) {
        setIsBandageOpen(false);
      }
    }
  };

  const medikitDialog = () => {
    return (
      <Dialog
        open={isBandageOpen}
        TransitionComponent={Slide}
        keepMounted
        onClose={() => setIsBandageOpen(false)}
      >
        <DialogTitle>Choix du pansement :</DialogTitle>
        <DialogContent>
          <Button
            onClick={() => selectBandage('band_1')}
            color="primary"
            variant="outlined"
            className="buttonBandage"
          >
            <Bandage className="bandageSelection" />
          </Button>
          <Button
            onClick={() => selectBandage('band_2')}
            color="primary"
            variant="outlined"
            className="buttonBandage"
          >
            <Bandage2 className="bandageSelection" />
          </Button>
        </DialogContent>
      </Dialog>
    );
  };

  const getArrow = type => {
    let rot = '';
    switch (type) {
      case 'N':
        rot = 'rot90';
        break;
      case 'E':
        rot = 'rot180';
        break;
      case 'S':
        rot = 'rot270';
        break;
      default:
        break;
    }
    return (
      <Button
        key={type}
        variant="contained"
        color="secondary"
        className="powerButton"
        onClick={() => sendMove(type)}
      >
        <Arrow className={'power ' + rot} />
      </Button>
    );
  };

  const getMedikit = () => {
    return (
      <Button
        key="Medikit"
        variant="contained"
        color="secondary"
        className="powerButton"
        onClick={tryOpenBandage}
        disabled={isDisabled || !isOnMedikit}
      >
        <Medikit className="power" viewBox="-9 -9 80 80" />
      </Button>
    );
  };

  const getOrUseO2 = () => {
    if (isOnGauge) {
      const hasO2 = room.backpack.includes('O2');
      if (!hasO2) {
        toast.error(t('actions.noOxygen'), {
          toastId: 'noOxygen'
        });
        playSound('backpackError');
      } else {
        socket.emit('backpack.use.O2');
      }
    } else if (isOnO2) {
      if (room.backpack.length > 1) {
        toast.error(t('actions.inventFull'), {
          toastId: 'inventFull'
        });
        playSound('backpackError');
      } else {
        socket.emit('backpack.pickUp.O2');
        playSound('inventoryPut');
      }
    }
  };

  const getO2 = () => {
    const hasO2 = room.backpack.includes('O2');
    let child;
    if (hasO2) {
      child = <O2 className="power" />;
    } else {
      child = <O2Empty className="power" />;
    }
    return (
      <Button
        key="O2"
        variant="contained"
        color="secondary"
        className="powerButton"
        onClick={() => getOrUseO2()}
        disabled={isDisabled || (!isOnGauge && !isOnO2)}
      >
        {child}
      </Button>
    );
  };

  const useBandage = type => {
    socket.emit('backpack.use.bandage', type);
  };

  const getBandageButton = (type, index) => {
    let child;
    if (type === 'band_1') {
      child = <Bandage className="power" />;
    } else {
      child = <Bandage2 className="power" />;
    }
    return (
      <Button
        key={`${type}-${index}`}
        variant="contained"
        color="secondary"
        className="powerButton"
        onClick={() => useBandage(type)}
        disabled={isDisabled}
      >
        {child}
      </Button>
    );
  };

  return (
    <>
      {medikitDialog()}
      <div className="actions">
        {me.powers &&
          me.powers.map(p => {
            if (ALL_ARROWS.includes(p)) {
              return getArrow(p);
            }
            if (p === 'medikit') {
              return getMedikit();
            }
            if (p === 'O2') {
              return getO2();
            }
            return <div />;
          })}
      </div>
      {me.powers.includes('medikit') && (
        <div className="actions bandageSection">
          {room.backpack
            .filter(item => ['band_1', 'band_2'].includes(item))
            .map(getBandageButton)}
        </div>
      )}
    </>
  );
};

export default Actions;
