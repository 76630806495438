import React, { useState, useEffect, useMemo } from 'react';
import { Progress } from 'react-sweet-progress';
import './SweetProgressStyle.css';

import Tile from '../Tiles/Tile';
import Actions from '../Actions/Actions';
import { useWindowSize } from '../CustomHooks';
import { HAUTEUR, LARGEUR, SOUNDS } from '../../Constantes';
import { ReactComponent as BackPack } from '../../SVG/backpack.svg';
import { ReactComponent as Bandage } from '../../SVG/bandage.svg';
import { ReactComponent as Bandage2 } from '../../SVG/bandage2.svg';
import { ReactComponent as O2 } from '../../SVG/O2.svg';
import { ReactComponent as Spaceship } from '../../SVG/spaceship.svg';
import './Main.less';

let ambiance = null;

const Main = ({ room, socket, me, playSound }) => {
  const findTilePositionByProperty = property => {
    const y = room.map.tiles.findIndex(
      ligne => ligne.filter(tile => tile[property]).length > 0
    );
    if (y !== -1) {
      const x = room.map.tiles[y].findIndex(t => t[property]);
      return { x, y };
    }
    console.error(
      'Erreur 404 : Tile with property "' + property + '" not found'
    );
    return { x: -1, y: -1 };
  };

  const [tileSize, setTileSize] = useState(50);
  const [position, setPosition] = useState(room.position);
  const [color, setColor] = useState('hsla(120, 87%, 46%)');
  const [percent, setPercent] = useState(room.position);

  const map = useMemo(() => (room.map && room.map.tiles) || [], [
    room.map.tiles
  ]);

  const [width, height] = useWindowSize();
  const gauge = useMemo(() => {
    return findTilePositionByProperty('isGauge');
  }, []);
  const medikit = useMemo(() => {
    return findTilePositionByProperty('isMedikit');
  }, []);

  const move = newPosition => {
    setPosition(newPosition);
  };

  useEffect(() => {
    socket.on('astronaute.move', move);
  }, []);

  const startAmbiance = () => {
    if (me && me.isHost && room && room.status === 'game') {
      ambiance = new Audio(SOUNDS['ambiance']);
      ambiance.play();
      ambiance.loop = true;
      ambiance.volume = 0.4;
    }
  };

  useEffect(() => {
    if (room.status === 'game') {
      startAmbiance();
    } else {
      if (ambiance) {
        ambiance.pause();
      }
    }
  }, [room.status]);

  useEffect(() => {
    // Update la couleur et le percentage de la room
    setPercent(room.oxygen);

    // 120 en H de HSLA = vert ( on prend donc du pour120 et non %)
    const nextHSLA = room.oxygen; //* 120/100;
    setColor(`hsla(${nextHSLA}, 87%, 46%)`);
  }, [room.oxygen]);

  useEffect(() => {
    const size = Math.min(width / (LARGEUR + 2), height / (HAUTEUR + 2)); // Map de 10 * 6 tiles
    setTileSize(size);
  }, [height, width]);

  const emptyDot = () => (
    <svg
      viewBox="0 0 100 100"
      className="emptyDot item"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="50"
        cy="50"
        r="48"
        fill="#7d93a5"
        stroke="white"
        strokeWidth="2px"
      />
    </svg>
  );

  const renderItem = type => {
    if (type === 'band_1') {
      return <Bandage className="item bandage" />;
    } else if (type === 'band_2') {
      return <Bandage2 className="item bandage" />;
    } else if (type === 'O2') {
      return <O2 className="item bandage" />;
    }
  };

  const theme = {
    default: {
      color: '#0c0c0d' // Black --> invisible
    },
    currentTheme: {
      color: color,
      symbol: <O2 className="O2Progress" />
    }
  };

  const initialTrip = room.config.duree || 6 * 60;
  const tripPercent = ((initialTrip - room.trip) / initialTrip) * 100;

  const renderHost = () => (
    <>
      <div className="mainContent">
        <div className="hud">
          <div className="backpack">
            <BackPack className="sacados" />
            <div className="slot firstSlot">
              {room.backpack.length > 0
                ? renderItem(room.backpack[0])
                : emptyDot()}
            </div>
            <div className="slot secondSlot">
              {room.backpack.length > 1
                ? renderItem(room.backpack[1])
                : emptyDot()}
            </div>
          </div>
          <div>
            <Progress
              className="progressContainer"
              type="circle"
              symbolClassName="classSymbol"
              percent={percent}
              status="currentTheme"
              theme={theme}
            />
          </div>
        </div>
        <div className="map">
          {map.map((ligne, indexY) => (
            <div key={indexY}>
              {ligne.map((t, indexX) => (
                <Tile
                  key={indexX}
                  type={t.type}
                  style={{ height: tileSize + 'px', width: tileSize + 'px' }}
                  isAstronaute={position.x === indexX && position.y === indexY}
                  isO2={t.isO2}
                  isMedikit={t.isMedikit}
                  bandage={t.bandage}
                  isGauge={t.isGauge}
                  hole={t.hole}
                  isRotate={t.isRotate}
                />
              ))}
              <br />
            </div>
          ))}
        </div>
        <div className="trip">
          <Progress
            className="tripProgress"
            percent={tripPercent}
            theme={{
              default: {
                color: '#0c0c0d'
              },
              currentTheme: {
                color: '#009e9e'
              }
            }}
            strokeWidth={1}
            status="currentTheme"
          />
          <Spaceship className="miniShip" style={{ left: tripPercent + '%' }} />
        </div>
      </div>
    </>
  );

  if (me.isHost) {
    return renderHost();
  } else if (me.isHost === false) {
    return (
      <Actions
        socket={socket}
        me={me}
        room={room}
        isOnMedikit={medikit.x === position.x && medikit.y === position.y}
        isOnGauge={gauge.x === position.x && gauge.y === position.y}
        isOnO2={map[position.y][position.x].isO2}
        playSound={playSound}
      />
    );
  } else {
    return <div />;
  }
};

export default Main;
