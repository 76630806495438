import JoinSound from './Sounds/join.mp3';
import EchangeSound from './Sounds/vaisseau.mp3';
import Ambiance from './Sounds/ambiance.mp3';
import BlackHole from './Sounds/blackHole.mp3';
import O2pop from './Sounds/o2Pop.mp3';
import BackpackError from './Sounds/backpackError.mp3';
import Deplacement from './Sounds/deplacement.mp3';
import O2Refill from './Sounds/o2Refill.mp3';
import Win from './Sounds/win.mp3';
import Loose from './Sounds/loose.mp3';
import InventoryPut from './Sounds/inventoryPut.mp3';
import CloseHole from './Sounds/closeHole.mp3';

export const isProduction = process.env.NODE_ENV === 'production';
export const PORT = 7776;
export const VERSION = '1.1';
export const BASE_URL = isProduction
  ? `https://spacecheap.idontcare.fr:${PORT}`
  : `http://localhost:${PORT}`; //192.168.1.31

export const SOUNDS = {
  join: JoinSound,
  mapExchange: EchangeSound,
  ambiance: Ambiance,
  blackHole: BlackHole,
  o2pop: O2pop,
  backpackError: BackpackError,
  inventoryPut: InventoryPut,
  deplacement: Deplacement,
  o2refill: O2Refill,
  closeHole: CloseHole,
  win: Win,
  loose: Loose
};

export const ALL_TILES_TYPE = [
  'ES',
  'SW',
  'NE',
  'NW',
  'NS',
  'EW',
  'NESW',
  'NES',
  'ESW',
  'NSW',
  'NEW',
  '', // None
  'spawn'
];
export const LARGEUR = 10;
export const HAUTEUR = 6;

//MAPS
// prettier-ignore
export const FAUCON_MILLENIUM = {"name":"Faucon Millenium","tiles":[[{"type":"ES"},{"type":"ESW"},{"type":"ESW"},{"type":"ESW"},{"type":"ESW"},{"type":"ESW"},{"type":"ESW"},{"type":"ESW"},{"type":"ESW", "isSpawn":true},{"type":"SW"}],[{"type":"NS"},{"type":"NES"},{"type":"NESW"},{"type":"NSW"},{"type":"NES"},{"type":"NSW"},{"type":"NES"},{"type":"NESW"},{"type":"NSW"},{"type":"NS"}],[{"type":"NS"},{"type":"NES"},{"type":"NESW"},{"type":"NSW"},{"type":"NES"},{"type":"NSW"},{"type":"NES"},{"type":"NESW"},{"type":"NSW"},{"type":"NS"}],[{"type":"NS"},{"type":"NES"},{"type":"NESW"},{"type":"NSW"},{"type":"NES"},{"type":"NSW"},{"type":"NES"},{"type":"NESW"},{"type":"NSW"},{"type":"NS"}],[{"type":"NS"},{"type":"NES"},{"type":"NESW"},{"type":"NSW"},{"type":"NES"},{"type":"NSW"},{"type":"NES"},{"type":"NESW"},{"type":"NSW"},{"type":"NS"}],[{"type":"NE"},{"type":"NEW"},{"type":"NEW"},{"type":"NEW"},{"type":"NEW"},{"type":"NEW"},{"type":"NEW"},{"type":"NEW"},{"type":"NEW"},{"type":"NW"}]]};
// prettier-ignore
export const BROKEN_MIRROR ={"name":"Broken mirror","tiles":[[{"type":"ES", "isSpawn":true},{"type":"EW"},{"type":"EW"},{"type":"ESW"},{"type":"SW"},{"type":"ES"},{"type":"EW"},{"type":"EW"},{"type":"ESW"},{"type":"SW"}],[{"type":"NS"},{"type":"ES"},{"type":"EW"},{"type":"NW"},{"type":"NES"},{"type":"NW"},{"type":"ES"},{"type":"SW"},{"type":"NS"},{"type":"NS"}],[{"type":"NS"},{"type":"NE"},{"type":"EW"},{"type":"SW"},{"type":"NES"},{"type":"ESW"},{"type":"NW"},{"type":"NS"},{"type":"NS"},{"type":"NS"}],[{"type":"NS"},{"type":"ES"},{"type":"EW"},{"type":"NW"},{"type":"NES"},{"type":"NESW"},{"type":"SW"},{"type":"NS"},{"type":"NS"},{"type":"NS"}],[{"type":"NS"},{"type":"NE"},{"type":"EW"},{"type":"EW"},{"type":"NSW"},{"type":"NS"},{"type":"NS"},{"type":"ES"},{"type":"NESW"},{"type":"SW"}],[{"type":"NE"},{"type":"EW"},{"type":"EW"},{"type":"EW"},{"type":"NEW"},{"type":"NEW"},{"type":"NW"},{"type":"NE"},{"type":"NEW"},{"type":"NW"}]]};
//prettier-ignore
export const AL_KESH={"name":"Al'kesh","tiles":[[{"type":"ES"},{"type":"EW"},{"type":"EW"},{"type":"EW"},{"type":"EW"},{"type":"SW"},{"type":"ES"},{"type":"EW"},{"type":"EW"},{"type":"SW"}],[{"type":"NE"},{"type":"EW"},{"type":"EW"},{"type":"EW"},{"type":"ESW"},{"type":"NSW"},{"type":"NS"},{"type":"ES"},{"type":"SW"},{"type":"NS"}],[{"type":"ES"},{"type":"EW"},{"type":"EW"},{"type":"EW"},{"type":"NESW"},{"type":"NESW","isSpawn":true},{"type":"NW"},{"type":"NS"},{"type":"NS"},{"type":"NS"}],[{"type":"NE"},{"type":"EW"},{"type":"EW"},{"type":"EW"},{"type":"NESW"},{"type":"NESW"},{"type":"SW"},{"type":"NES"},{"type":"NSW"},{"type":"NS"}],[{"type":"ES"},{"type":"EW"},{"type":"EW"},{"type":"EW"},{"type":"NSW"},{"type":"NS"},{"type":"NS"},{"type":"NS"},{"type":"NS"},{"type":"NS"}],[{"type":"NE"},{"type":"EW"},{"type":"EW"},{"type":"EW"},{"type":"NW"},{"type":"NE"},{"type":"NW"},{"type":"NE"},{"type":"NEW"},{"type":"NW"}]]};
//prettier-ignore
export const X_WING={"name":"X-wing","tiles":[[{"type":"ES"},{"type":"EW"},{"type":"EW"},{"type":"ESW"},{"type":"EW"},{"type":"EW"},{"type":"ESW"},{"type":"ESW"},{"type":"EW"},{"type":"SW"}],[{"type":"NS"},{"type":""},{"type":""},{"type":"NS"},{"type":""},{"type":""},{"type":"NS"},{"type":"NS"},{"type":""},{"type":"NS"}],[{"type":"NS"},{"type":""},{"type":""},{"type":"NE"},{"type":"ESW"},{"type":"ESW"},{"type":"NW"},{"type":"NE"},{"type":"EW"},{"type":"NSW"}],[{"type":"NES"},{"type":"EW"},{"type":"SW"},{"type":"ES"},{"type":"NEW"},{"type":"NEW"},{"type":"SW"},{"type":""},{"type":""},{"type":"NS"}],[{"type":"NS"},{"type":""},{"type":"NS"},{"type":"NS"},{"type":""},{"type":""},{"type":"NS"},{"type":""},{"type":""},{"type":"NS"}],[{"type":"NE"},{"type":"EW"},{"type":"NEW"},{"type":"NEW"},{"type":"EW"},{"type":"EW"},{"type":"NEW","isSpawn":true},{"type":"EW"},{"type":"EW"},{"type":"NW"}]]};
//prettier-ignore
export const JUMPER={"name":"Jumper","tiles":[[{"type":"ES"},{"type":"SW"},{"type":""},{"type":"ES"},{"type":"SW"},{"type":""},{"type":"ES"},{"type":"SW"},{"type":""},{"type":""}],[{"type":"NS"},{"type":"NS"},{"type":""},{"type":"NS"},{"type":"NS"},{"type":""},{"type":"NS"},{"type":"NS"},{"type":""},{"type":""}],[{"type":"NS"},{"type":"NES"},{"type":"EW"},{"type":"NSW"},{"type":"NES"},{"type":"EW","isSpawn":true},{"type":"NSW"},{"type":"NE"},{"type":"EW"},{"type":"SW"}],[{"type":"NS"},{"type":"NES"},{"type":"EW"},{"type":"NSW"},{"type":"NES"},{"type":"EW"},{"type":"NSW"},{"type":"ES"},{"type":"EW"},{"type":"NW"}],[{"type":"NS"},{"type":"NS"},{"type":""},{"type":"NS"},{"type":"NS"},{"type":""},{"type":"NS"},{"type":"NS"},{"type":""},{"type":""}],[{"type":"NE"},{"type":"NW"},{"type":""},{"type":"NE"},{"type":"NW"},{"type":""},{"type":"NE"},{"type":"NW"},{"type":""},{"type":""}]]};
//prettier-ignore
export const ENTERPRISE={"name":"USS Enterprise","tiles":[[{"type":"ES"},{"type":"EW"},{"type":"EW"},{"type":"EW"},{"type":"EW"},{"type":"EW"},{"type":"ESW"},{"type":"ESW"},{"type":"ESW"},{"type":"SW"}],[{"type":"NES"},{"type":"EW"},{"type":"ESW"},{"type":"SW"},{"type":""},{"type":""},{"type":"NS"},{"type":"NES"},{"type":"NESW"},{"type":"NSW"}],[{"type":"NES"},{"type":"SW"},{"type":"NE"},{"type":"NESW"},{"type":"SW"},{"type":""},{"type":"NS"},{"type":"NES"},{"type":"NESW"},{"type":"NW"}],[{"type":"NES"},{"type":"NW"},{"type":""},{"type":"NE"},{"type":"NESW"},{"type":"SW"},{"type":"NS"},{"type":"NE"},{"type":"NESW"},{"type":"SW"}],[{"type":"NS"},{"type":""},{"type":"ES"},{"type":"SW"},{"type":"NE"},{"type":"NESW"},{"type":"NSW"},{"type":""},{"type":"NE"},{"type":"NSW"}],[{"type":"NE","isSpawn":true},{"type":"EW"},{"type":"NEW"},{"type":"NEW"},{"type":"EW"},{"type":"NEW"},{"type":"NEW"},{"type":"EW"},{"type":"EW"},{"type":"NW"}]]};
