import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { LanguageENUM } from './i18n';
import './LangageSelector.less';

const LangageSelector = () => {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);

  const changeLanguage = (event) => {
    let language = event.target.value;
    switch (language) {
      case LanguageENUM.EN:
        setCurrentLang(LanguageENUM.EN);
        i18n.changeLanguage(LanguageENUM.EN);
        break;
      case LanguageENUM.FR:
      default:
        setCurrentLang(LanguageENUM.FR);
        i18n.changeLanguage(LanguageENUM.FR);
        break;
    }
  };

  return (
    <FormControl className="langSelector">
      <InputLabel>{t('common.lang')}</InputLabel>
      <Select value={currentLang} onChange={changeLanguage}>
        <MenuItem value={LanguageENUM.FR}>Français</MenuItem>
        <MenuItem value={LanguageENUM.EN}>English</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LangageSelector;
