import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import MapEditor from './Components/MapEditor/MapEditor';
import LangageSelector from './Translation/LangageSelector';
import InstallDrawer from './Components/InstallDrawer/InstallDrawer';

import App from './App';
import './App.less';

const Router = () => {
  return (
    <HashRouter>
      <Switch>
        <Route path="/mapEditor">
          <div className="fullScreen">
            <MapEditor />
          </div>
        </Route>
        <Route path="/" exact>
          <App />
        </Route>
        <Redirect to="/" />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={4}
      />
      <LangageSelector />
      <InstallDrawer />
    </HashRouter>
  );
};

export default Router;
