import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import { ReactComponent as MeteoritesHappen } from '../../SVG/animated/meteoriteHappen.svg';
import { ReactComponent as FixBlackHole } from '../../SVG/animated/fixBlackHole.svg';
import { ReactComponent as ShufflePowers } from '../../SVG/animated/shufflePowers.svg';
import Loading1 from '../../SVG/gif/loading.gif';
import Loading2 from '../../SVG/gif/loading.webp';
import Loading3 from '../../SVG/gif/loading2.gif';
import Loading4 from '../../SVG/gif/loading3.gif';
import Loading5 from '../../SVG/gif/loading4.gif';
import Loading6 from '../../SVG/gif/loading5.gif';
import { rand } from '../../utils';
import './Tuto.less';

const LOADING = [Loading1, Loading2, Loading3, Loading4, Loading5, Loading6];

const Tuto = ({ socket, me }) => {
  const { t } = useTranslation();
  const [indexLoading, setIndexLoading] = useState(rand(0, LOADING.length));

  const switchGif = () => {
    setIndexLoading((indexLoading + 1) % (LOADING.length - 1));
  };

  const startGame = () => {
    socket.emit('room.endTuto');
  };

  const renderTuto = () => (
    <div className="tuto">
      <div className="step">
        <div className="text">{t('tuto.meteorites')}</div>
        <MeteoritesHappen className="animation" />
      </div>
      <div className="step">
        <FixBlackHole className="animation fixHole" />
        <div className="text text-left">{t('tuto.fixHoles')}</div>
      </div>
      <div className="step">
        <div className="text">{t('tuto.actions')}</div>
        <ShufflePowers className="animation shuffle" />
      </div>
      <Button
        variant="contained"
        color="primary"
        className="startButton"
        onClick={startGame}
      >
        Let's do it
      </Button>
    </div>
  );

  const renderRandomGif = () => (
    <div className="gifWrapper">
      <img
        src={LOADING[indexLoading]}
        className="loadingGif"
        onClick={switchGif}
        alt="gif loading"
      />
    </div>
  );

  return <>{me.isHost ? renderTuto() : renderRandomGif()}</>;
};

export default Tuto;
