import React, { useState, useEffect, Suspense, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import {
  BROKEN_MIRROR,
  FAUCON_MILLENIUM,
  AL_KESH,
  X_WING,
  JUMPER,
  ENTERPRISE
} from '../../Constantes';
import DifficultyConfig from './DifficultyConfig';
import './Lobby.less';

const MapRendering = React.lazy(() => import('./MapRendering'));

const Lobby = ({ socket, room, me }) => {
  const localStore = localStorage.getItem('maps');
  const localMaps = localStore ? JSON.parse(localStore) : [];

  const getInitMap = () => {
    if (me.isHost) {
      return [
        BROKEN_MIRROR,
        FAUCON_MILLENIUM,
        AL_KESH,
        X_WING,
        JUMPER,
        ENTERPRISE,
        ...localMaps
      ];
    } else {
      return localMaps;
    }
  };

  const { t } = useTranslation();

  const [allMaps, setAllMaps] = useState([]);
  const [mapSelected, setMapSelected] = useState(null);
  const [theReceivedMap, setTheReceivedMap] = useState(null);

  // Config
  const [oxygenRefill, setOxygenRefill] = useState(room.config.oxygenRefill);
  const [duree, setDuree] = useState(room.config.duree / 60);
  const [holeReduce, setHoleReduce] = useState(room.config.holeReduce);
  const [hasRotate, setHasRotate] = useState(room.config.hasRotate || false);
  const [nbRotate, setNbRotate] = useState(room.config.nbRotate);

  const receiveMap = sentMap => {
    setTheReceivedMap(sentMap);
    toast(
      `${t('lobby.ship')} '${sentMap.name}' ${t('lobby.getFrom')} ${
        sentMap.from
      }`
    );
  };

  useEffect(() => {
    socket.on('map.receive', receiveMap);
    return () => {
      socket.removeAllListeners('map.receive');
    };
  }, []);

  useEffect(() => {
    setAllMaps(getInitMap());
  }, [me.isHost]);

  useEffect(() => {
    if (theReceivedMap !== null) {
      setAllMaps([...allMaps, theReceivedMap]);
    }
  }, [theReceivedMap]);

  const lockRoom = () => {
    const config = {
      holeReduce,
      oxygenRefill,
      duree: duree * 60,
      hasRotate,
      nbRotate
    };
    socket.emit('room.lock', mapSelected, config, errorCode => {
      toast.warning(t(errorCode));
    });
  };

  const shareMap = () => {
    const toShare = { ...mapSelected, from: me.name };
    socket.emit('map.share', toShare, retour => {
      if (retour.ok) {
        toast.success(t(retour.msgCode));
        const newAllMaps = [...allMaps];
        const found = newAllMaps.find(m => m.name === mapSelected.name);
        found.alreadySent = true;
        setAllMaps(newAllMaps);
        setMapSelected(null);
      } else {
        toast.warning(retour.msg);
      }
    });
  };

  const mapChoice = useMemo(() => {
    console.log('mapChoice Memoized !');
    return (
      <MapRendering
        mapSelected={mapSelected}
        setMapSelected={setMapSelected}
        mapsToRender={allMaps}
        mapSize={{
          height: '4vmin',
          width: '4vmin'
        }}
      />
    );
  }, [mapSelected, allMaps]);

  return (
    <div className="lobby">
      {me.isHost && (
        <>
          <div className="checkList">
            <div>
              <div className="checklistTitle">{t('lobby.settings')}</div>
              <div className="codeMission">
                {t('lobby.missionCode')} : «
                <span className="idRoom">{room.id}</span>»
              </div>
              <DifficultyConfig
                oxygenRefill={oxygenRefill}
                setOxygenRefill={setOxygenRefill}
                duree={duree}
                setDuree={setDuree}
                holeReduce={holeReduce}
                setHoleReduce={setHoleReduce}
                hasRotate={hasRotate}
                setHasRotate={setHasRotate}
                nbRotate={nbRotate}
                setNbRotate={setNbRotate}
              />
            </div>
            <div>
              <div className="checklistTitle">{t('lobby.crewmate')} :</div>
              <ul className="ulLobby ul2cols">
                {room.playerList.map((p, index) => (
                  <li className="liPrimary playerName" key={index}>
                    {p.name.length > 14
                      ? p.name.substring(0, 14) + '...'
                      : p.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="checklistTitle">{t('lobby.shipChoose')} :</div>
          <Suspense
            fallback={<div className="loadingMaps">{t('lobby.loading')}</div>}
          >
            {mapChoice}
          </Suspense>
          <Button
            variant="contained"
            color="primary"
            className="lobbyButton"
            onClick={lockRoom}
            disabled={mapSelected === null}
          >
            {t('lobby.takeOff')}
          </Button>
        </>
      )}
      {!me.isHost && (
        <>
          <div className="giveShips">{t('lobby.explainShare')}</div>
          <Suspense fallback={<div>{t('lobby.loading')}</div>}>
            <MapRendering
              mapSelected={mapSelected}
              setMapSelected={setMapSelected}
              mapsToRender={allMaps.filter(m => !m.alreadySent)}
              hadAtLeastOne={allMaps.length > 0}
              mapSize={{
                height: '6vmin',
                width: '6vmin'
              }}
            />
          </Suspense>
          <Button
            variant="contained"
            color="primary"
            className="lobbyButton"
            onClick={shareMap}
            disabled={mapSelected === null || mapSelected.alreadySent}
          >
            {t('lobby.shareShip')}
          </Button>
        </>
      )}
    </div>
  );
};

export default Lobby;
