import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Button } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';

import Lobby from './Components/Lobby/Lobby';
import RoomInit from './Components/RoomInit';
import Main from './Components/Main/Main';
import Tuto from './Components/Tuto/Tuto';
import End from './Components/End/End';

import { isProduction, BASE_URL, SOUNDS } from './Constantes';

// Gestion du storage : En dev on utilise la session pour pouvoir ouvrir plusieurs tabs à la fois !
const storage = isProduction ? localStorage : sessionStorage;

const App = () => {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const [socket, setSocket] = useState(null);
  const [me, setMe] = useState({});
  const [room, setRoom] = useState(null);
  const [showModalReco, setShowModalReco] = useState(false);

  useEffect(() => {
    // Empêche le menu contextuel sur mobile au longPress
    // and btw le click droit aussi donc que en prod
    if (isProduction) {
      window.oncontextmenu = function(event) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      };
    }

    // Créer la connection au serveur set le socket !
    const socketInit = io.connect(BASE_URL, {
      secure: isProduction,
      reconnection: isProduction
    });
    console.log('Connection en mode : ', isProduction ? 'Production' : 'dev');
    setSocket(socketInit);
  }, []);

  const playSound = soundId => {
    const audio = new Audio(SOUNDS[soundId]);
    if (soundId === 'deplacement') {
      audio.volume = 0.5;
    }
    audio.play();
  };

  const updateRoom = newRoom => {
    setRoom(newRoom);
    if (newRoom.playerList && newRoom.playerList.length > 0) {
      const newMe = newRoom.playerList.find(p => p.id === socket.id);
      if (newMe) {
        setMe(newMe);
      }
    }
    if (newRoom.host && socket.id === newRoom.host) {
      setMe({ isHost: true, id: socket.id });
    }
  };

  const saveDatasForReco = (id, roomId) => {
    if (id && roomId) {
      // Enregistre les infos de la room
      const pastId = storage.getItem('pastId');
      const pastRoomId = storage.getItem('pastRoomId');
      if (!pastId || !pastRoomId || pastId !== id || pastRoomId !== roomId) {
        console.log(`Save for reco : {pastId: ${id}, pastRoomId: ${roomId}}`);
        storage.setItem('pastId', id);
        storage.setItem('pastRoomId', roomId);
      }
    }
  };

  const reconnectCallback = ({ isOk, socketId, roomId }) => {
    if (isOk) {
      saveDatasForReco(socketId, roomId);
    } else {
      toast.error(t('app.missionCanceled'));
    }
  };

  const playerHasDeco = name => {
    toast.error(t('app.playerLeft', { name: name }));
  };

  const playerHasReco = name => {
    toast.success(t('app.playerReco', { name: name }));
  };

  const reconnect = () => {
    const { pastRoomId, pastId } = showModalReco;
    socket.emit(
      'room.reconnect.join',
      { pastRoomId, pastId },
      reconnectCallback
    );
    setShowModalReco(false);
  };

  const refuseReco = () => {
    setShowModalReco(false);
    storage.removeItem('pastId');
    storage.removeItem('pastRoomId');
  };

  useEffect(() => {
    if (socket != null) {
      // Ici on gère les évenement reçu du server
      socket.on('playSound', playSound);
      socket.on('player.hasDeco', playerHasDeco);
      socket.on('player.hasReco', playerHasReco);
      socket.on('room.update', updateRoom);
      socket.on('connect_error', e => {
        console.error('Connect error : ' + e);
      });
      socket.on('disconnect', reason => {
        console.log('MAYDAY MAYDAY SERVER LOST : ' + reason);
      });

      // Si des infos sont trouvées localement on tente une reconnection à l'ancienne partie
      const pastId = storage.getItem('pastId');
      const pastRoomId = storage.getItem('pastRoomId');
      if (pastId && pastRoomId) {
        socket.emit(
          'room.reconnect.exist',
          {
            pastRoomId,
            pastId
          },
          roomStillExist => {
            if (roomStillExist) {
              setShowModalReco({
                pastRoomId,
                pastId
              });
            } else {
              storage.removeItem('pastId');
              storage.removeItem('pastRoomId');
            }
          }
        );
      }
    }
  }, [socket]);

  useEffect(() => {
    if (room) {
      let newIndex = 0;
      switch (room.status) {
        case 'lobby':
          newIndex = 0;
          break;
        case 'tuto':
          newIndex = 1;
          saveDatasForReco(me && me.id, room && room.id);
          break;
        case 'game':
          newIndex = 2;
          break;
        case 'end':
          newIndex = 3;
          break;
        default:
          newIndex = 0;
      }
      if (newIndex !== index) {
        setIndex(newIndex);
      }
    }
  }, [room && room.status]);

  return (
    <>
      <SwipeableViews resistance index={index} disabled className="swipViews">
        <div className="fullScreen">
          {room && room.status === 'lobby' ? (
            <Lobby room={room} socket={socket} me={me} />
          ) : (
            <RoomInit socket={socket} />
          )}
        </div>
        <div className="fullScreen tuto">
          {room != null && <Tuto socket={socket} me={me} />}
        </div>
        <div className="fullScreen main">
          {room != null && room.map && index === 2 && (
            <Main room={room} socket={socket} me={me} playSound={playSound} />
          )}
        </div>
        <div className="fullScreen">
          {room != null && <End room={room} socket={socket} me={me} />}
        </div>
      </SwipeableViews>
      <Dialog
        open={!!showModalReco}
        disableBackdropClick
        TransitionComponent={Slide}
        keepMounted
        onClose={() => setShowModalReco(false)}
      >
        <DialogContent>
          <div className="titleModal">{t('app.deserter')}</div>
          <div>{t('app.crewMatesNeedsYou')}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={refuseReco} color="secondary" variant="contained">
            <div>{t('app.no')}</div>
          </Button>
          <Button onClick={reconnect} color="primary" variant="contained">
            <div>{t('app.sirYesSir')}</div>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default App;
